import axios from 'axios'
import { PROXY_ADDRESS } from '../apiConfig'
import makeAuthedRequest from '../axiosConfig'

export const createAccount = (data) => {
  return axios.post(`${PROXY_ADDRESS}/user/register/`, data)
}

export const activateAccount = () => {
  const activationToken = localStorage.getItem('activate_account_token')
  return makeAuthedRequest(
    `${PROXY_ADDRESS}/user/activate_account/`,
    'get',
    null,
    `Token ${activationToken}`
  )
}

export const getChangePass = () => {
  const resetPassToken = localStorage.getItem('pw_reset_access_token')
  return makeAuthedRequest(
    `${PROXY_ADDRESS}/user/change_pass/`,
    'get',
    null,
    `Token ${resetPassToken}`
  )
}

export const putChangePass = (data) => {
  const resetPassToken = localStorage.getItem('pw_reset_access_token')
  return makeAuthedRequest(
    `${PROXY_ADDRESS}/user/change_pass/`,
    'put',
    data,
    `Token ${resetPassToken}`
  )
}

export const recoverAcc = (data) => {
  return axios.post(`${PROXY_ADDRESS}/user/recover/`, data)
}

export const login = (data) => {
  return axios.post(`${PROXY_ADDRESS}/user/login/`, data)
}

export const verifyUserToken = (data) => {
  const bearerToken =
    localStorage.getItem('jwt_access_token') || sessionStorage.getItem('jwt_access_token')
  return makeAuthedRequest(
    `${PROXY_ADDRESS}/user/api/token/verify/`,
    'post',
    data,
    `Bearer ${bearerToken}`
  )
}

export const refreshUserToken = (data) => {
  const bearerToken =
    localStorage.getItem('jwt_access_token') || sessionStorage.getItem('jwt_access_token')
  return makeAuthedRequest(
    `${PROXY_ADDRESS}/user/api/token/refresh/`,
    'post',
    data,
    `Bearer ${bearerToken}`
  )
}

export const requestActivationLink = (data) => {
  return axios.post(`${PROXY_ADDRESS}/user/request_activation_link/`, data)
}
