import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const PrivateRoute = ({ children }) => {
  const loggedIn = useSelector((state) => state.user.isLoggedIn)
  return !loggedIn ? <Navigate to="/login" /> : children
}

export const PublicRoute = ({ children }) => {
  const loggedIn = useSelector((state) => state.user.isLoggedIn)
  return loggedIn ? <Navigate to="/" /> : children
}
