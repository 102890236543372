import React from 'react'
import './SimpleCard.scss'
import classnames from 'classnames'
import SmallStyledLoading from 'components/loading/SmallStyledLoading'

const SimpleCard = ({ children, className, variant, loading = false, ...props }) => {
  if (loading) return <CardSkeleton />

  return (
    <div
      className={classnames('simple-card-container', className, variant, {
        interactive: props.onClick,
      })}
      {...props}
    >
      {children}
    </div>
  )
}

export default SimpleCard

const CardSkeleton = () => {
  return (
    <div className="simple-card-container">
      <SmallStyledLoading />
    </div>
  )
}
