import React, { Suspense, useEffect, useState } from 'react'
import './App.scss'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import StyledLoading from './components/loading/StyledLoading'
import { useRefreshUser } from 'custom-hooks/api/useRefreshUser'
import {
  HomeAuthorized,
  Login,
  ResendActivationContainer,
  ResetPassword,
  ResetPassSetNewContainer,
  TempRegister,
  RegisterActivationContainer,
  AccountActivation,
  Account,
  QrGeneralInfo,
  QRCodes,
  QrCodeCreateEdit,
  QrForms,
  QrFrames,
  FrameEditor,
  Stats,
  Companies,
  Locations,
  Feedback,
  Faq,
  Register,
  QrFeedback,
  StyledSnackbar,
  FormEditor,
  PWA,
} from './utils/lazyImports'
import { PublicRoute, PrivateRoute } from 'components/custom-routes/AuthedRouting'
import { useSelector } from 'react-redux'
import PageContainer from 'components/page-container/PageContainer'
import Sidebar from 'components/navbar/app-sidebar/Sidebar'
import CreatorSidebar from 'components/navbar/creator-navbar/CreatorSidebar'
import PWAprompt from 'PWAprompt'

const AppRouting = () => {
  const { verifyRefreshToken } = useRefreshUser()
  const snackbar = useSelector((state) => state.snackbar)
  const user = useSelector((state) => state.user)
  const location = useLocation().pathname
  const [styles, setStyles] = useState({ position: 'absolute', top: '-1000px', left: '-1000px' })
  const isPWAInstalled = useSelector((state) => state.pwa.isPWAInstalled)

  useEffect(() => {
    verifyRefreshToken()
  }, [])

  useEffect(() => {
    if (location?.includes('app') && !isPWAInstalled) {
      setStyles({ position: 'absolute', top: '400px', left: '10px' })
    } else {
      setStyles({ position: 'absolute', top: '-1000px', left: '-1000px' })
    }
  }, [location])

  if (!user.checkedAuth) return <StyledLoading />

  return (
    <div id="app-page-container" className="app-page-container">
      {user.isLoggedIn && <Sidebar />}
      {/* public routes */}
      <div className="pwa-prompt" id="find-pwa-prompt" style={styles}>
        <PWAprompt />
      </div>
      <PageContainer className="app-routing-container">
        <CreatorSidebar />
        <Suspense fallback={<StyledLoading />}>
          <Routes>
            <Route
              path="/login/"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/resend-activation/"
              element={
                <PublicRoute>
                  <ResendActivationContainer />
                </PublicRoute>
              }
            />
            <Route
              path="/recover-account/"
              element={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <PublicRoute>
                  <ResetPassSetNewContainer />
                </PublicRoute>
              }
            />
            <Route
              path="/register/"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path="/activate"
              element={
                <PublicRoute>
                  <RegisterActivationContainer />
                </PublicRoute>
              }
            />

            {/* private routes */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <HomeAuthorized />
                </PrivateRoute>
              }
            />
            <Route
              path="/account-setup/"
              element={
                <PrivateRoute>
                  <AccountActivation />
                </PrivateRoute>
              }
            />
            <Route
              path="/account/"
              element={
                <PrivateRoute>
                  <Account />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator"
              element={
                <PrivateRoute>
                  <QrGeneralInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator/codes/"
              element={
                <PrivateRoute>
                  <QRCodes />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator/editor/codes/:id/"
              element={
                <PrivateRoute>
                  <QrCodeCreateEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator/forms/"
              element={
                <PrivateRoute>
                  <QrForms />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator/editor/forms/:id/"
              element={
                <PrivateRoute>
                  <FormEditor />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator/frames/"
              element={
                <PrivateRoute>
                  <QrFrames />
                </PrivateRoute>
              }
            />
            <Route
              path="/creator/editor/frames/:id/"
              element={
                <PrivateRoute>
                  <FrameEditor />
                </PrivateRoute>
              }
            />
            <Route
              path="/stats/"
              element={
                <PrivateRoute>
                  <Stats />
                </PrivateRoute>
              }
            />
            <Route
              path="/companies/"
              element={
                <PrivateRoute>
                  <Companies />
                </PrivateRoute>
              }
            />
            <Route
              path="/locations/"
              element={
                <PrivateRoute>
                  <Locations />
                </PrivateRoute>
              }
            />
            <Route
              path="/feedback/"
              element={
                <PrivateRoute>
                  <Feedback />
                </PrivateRoute>
              }
            />
            <Route
              path="/faq/"
              element={
                <PrivateRoute>
                  <Faq />
                </PrivateRoute>
              }
            />

            {!isPWAInstalled && (
              <Route
                path="/app/"
                element={
                  <PrivateRoute>
                    <PWA />
                  </PrivateRoute>
                }
              />
            )}

            {/* unrestricted routes */}
            <Route exact path="/qr-feedback" element={<QrFeedback />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          {snackbar?.status && (
            <StyledSnackbar isOpen={!!snackbar.status} severity={snackbar.status} children={snackbar.message} />
          )}
        </Suspense>
      </PageContainer>
    </div>
  )
}

export default AppRouting
