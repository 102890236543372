import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  forms: null,
}

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setFormsSlice(state, action) {
      state.forms = action.payload
    },

    addFormSlice(state, action) {
      const form = action.payload
      if (state.forms) {
        state.forms = state.forms.concat(form)
      }
    },

    removeFormSlice(state, action) {
      const formArray = action.payload
      if (state.forms) {
        if (formArray.id) {
          state.forms = state.forms.filter((fm) => fm.id !== formArray.id)
        } else {
          const ids = formArray.ids
          state.forms = state.forms.filter((fm) => !ids.includes(fm.id))
        }
      }
    },

    changeFormSlice(state, action) {
      const form = action.payload
      if (state.forms) {
        state.forms = state.forms.map((fm) => {
          if (fm.id === form.id) {
            return { ...fm, ...form }
          }
          return fm
        })
      }
    },
  },
})

export const { setFormsSlice, addFormSlice, removeFormSlice, changeFormSlice } = formsSlice.actions

export default formsSlice.reducer
