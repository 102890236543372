import React from 'react'
import './FallbackErrorPage.scss'
import { useTranslation } from 'react-i18next'
import PrimaryButton from '../buttons/PrimaryButton'
import SimpleCard from 'components/styled-cards/SimpleCard'
import { NavLink } from 'react-router-dom'
import FailIcon from 'components/icons/success-states/FailIcon'
import Typography from 'components/typography/Typography'

const FallbackErrorPage = () => {
  const { t } = useTranslation()
  return (
    <div className="error-boundary-container">
      <SimpleCard className="error-boundary">
        <FailIcon />
        <Typography variant="h2 error">{t('error-boundary-title')}</Typography>
        <Typography variant="subheading3 error">{t('error-boundary-subtitle')}</Typography>
        <div className="nav-btn">
          <NavLink to="/">
            <PrimaryButton>{t('fallback-error-btn-home')}</PrimaryButton>
          </NavLink>
        </div>
      </SimpleCard>
    </div>
  )
}

export default FallbackErrorPage
