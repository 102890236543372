import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import './StyledLoading.scss'
import { useTranslation } from 'react-i18next'
import Typography from 'components/typography/Typography'

const StyledLoading = () => {
  const { t } = useTranslation()

  return (
    <div className="styled-loading">
      <div className="qr-code">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Typography variant="body1" className="loading-text">
        {t('loading-message')}
      </Typography>
    </div>
  )
}

export default StyledLoading
