import React from 'react'
import ReactDOM from 'react-dom/client'
import Root from './App'
import './translations/i18n'
import { Provider } from 'react-redux'
import store from './redux/store.js'
import './App.scss'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <Root />
  </Provider>
)

serviceWorkerRegistration.register()
