import { combineReducers } from 'redux'
import userReducer from './ducks/user'
import snackbarReducer from './ducks/snackbar'
import companiesReducer from './ducks/companies'
import locationsReducer from './ducks/locations'
import codesReducer from './ducks/codes'
import themeReducer from './ducks/theme'
import statsReducer from './ducks/stats'
import feedbacksReducer from './ducks/feedbacks'
import formsReducer from './ducks/forms'
import framesReducer from './ducks/frames'
import pwaReducer from './ducks/pwa'

export default combineReducers({
  user: userReducer,
  snackbar: snackbarReducer,
  companies: companiesReducer,
  locations: locationsReducer,
  codes: codesReducer,
  theme: themeReducer,
  stats: statsReducer,
  feedbacks: feedbacksReducer,
  forms: formsReducer,
  frames: framesReducer,
  pwa: pwaReducer,
})
