import React, { useState, useEffect } from 'react'
import FallbackErrorPage from './FallbackErrorPage'
import { useLocation } from 'react-router-dom'

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error)
    this.props.setHasError(true)
  }

  getChildren = () => {
    if (this.props.hasError) {
      // You can render any custom fallback UI
      return <FallbackErrorPage />
    }

    return this.props.children
  }

  render() {
    return this.getChildren()
  }
}

const BoundaryKeyHandler = ({ ...props }) => {
  const location = useLocation()
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    setHasError(false)
  }, [location])

  return (
    <ErrorBoundary hasError={hasError} setHasError={setHasError}>
      {props.children}
    </ErrorBoundary>
  )
}

export default BoundaryKeyHandler
