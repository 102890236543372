import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import './StyledLoading.scss'

const SmallStyledLoading = () => {
  return (
    <div className="small-styled-loading">
      <CircularProgress />
    </div>
  )
}

export default SmallStyledLoading
