import { createSlice } from '@reduxjs/toolkit'

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    status: '',
    message: ''
  },
  reducers: {
    setError(state, action) {
      state.message = action.payload
      state.status = 'error'
    },
    setSuccess(state, action) {
      state.message = action.payload
      state.status = 'success'
    },
    setInfo(state, action) {
      state.message = action.payload
      state.status = 'info'
    },
    setWarning(state, action) {
      state.message = action.payload
      state.status = 'warning'
    },
    clearSnackbar(state) {
      state.message = ''
      state.status = ''
    }
  }
})

export const {
  setError,
  setSuccess,
  setInfo,
  setWarning,
  clearSnackbar
} = snackbarSlice.actions

export default snackbarSlice.reducer

/*
     _      _      _
  __(o)< __(o)> __(o)=
  \___)  \___)  \___)

*/
